<template>
  <div class="table-mundial-playoff-informador">
    <template v-if="season == 1512">
      <img class="img4-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/brackes_03.png" />
    </template>
    <template v-if="season == 881">
      <img class="img4-cont" src="https://golstatsimages.blob.core.windows.net/golstats-bets/Brackets_rusia_2018.png" />
    </template>
  </div>
</template>
<script>
export default {
  name: 'TableMundialPlayOffInformador',
  components: {},
  props: {
    season: {
      type: Number,
    },
  },
  watch: {},
  data() {
    return {};
  },
  async created() {},
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.table-mundial-playoff-informador {
  width: 100%;
  height: auto;
  padding: 15px;
  margin-top: 15px;
}

.img4-cont {
  display: inline-block;
}
</style>
